@font-face {
  font-family: "Avenir Next";
  src: local("Avenir Next Bold"), local("Avenir-Next-Bold"),
    url("AvenirNext-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next";
  src: local("Avenir Next Bold Italic"), local("Avenir-Next-Bold-Italic"),
    url("AvenirNext-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next";
  src: local("Avenir Next Demi"), local("Avenir-Next-Demi"),
    url("AvenirNext-Demi.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next";
  src: local("Avenir Next Demi Italic"), local("Avenir-Next-Demi-Italic"),
    url("AvenirNext-DemiItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next";
  src: local("Avenir Next Heavy"), local("Avenir-Next-Heavy"),
    url("AvenirNext-Heavy.ttf") format("truetype");
  font-weight: 650;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next";
  src: local("Avenir Next Heavy Italic"), local("Avenir-Next-Heavy-Italic"),
    url("AvenirNext-HeavyItalic.ttf") format("truetype");
  font-weight: 650;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next";
  src: local("Avenir Next Italic"), local("Avenir-Next-Italic"),
    url("AvenirNext-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next";
  src: local("Avenir Next Light"), local("Avenir-Next-Light"),
    url("AvenirNext-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next";
  src: local("Avenir Next Light Italic"), local("Avenir-Next-Light-Italic"),
    url("AvenirNext-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next";
  src: local("Avenir Next Medium"), local("Avenir-Next-Medium"),
    url("AvenirNext-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next";
  src: local("Avenir Next Medium Italic"), local("Avenir-Next-Medium-Italic"),
    url("AvenirNext-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next";
  src: local("Avenir Next Regular"), local("Avenir-Next-Regular"),
    url("AvenirNext-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next";
  src: local("Avenir Next Thin"), local("Avenir-Next-Thin"),
    url("AvenirNext-Thin.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next";
  src: local("Avenir Next Thin Italic"), local("Avenir-Next-Thin-Italic"),
    url("AvenirNext-ThinItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next";
  src: local("Avenir Next Ultra Light"), local("Avenir-Next-Ultra-Light"),
    url("AvenirNext-UltraLight.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next";
  src: local("Avenir Next Ultra Light It"), local("Avenir-Next-Ultra-Light"),
    url("AvenirNext-UltraLightIt.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}